/* eslint-disable react/prop-types .*/
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Context from '../context';
import ContentItem from '../components/ContentItem';
import './page.scss';
import '../styles/animate.min.css';

const Patterns = [
	{
		pattern: /^p-.*/,
	},
	{
		pattern: /^px-.*/,
	},
	{
		pattern: /^py-.*/,
	},
	{
		pattern: /^pt-.*/,
	},
	{
		pattern: /^pr-.*/,
	},
	{
		pattern: /^pb-.*/,
	},
	{
		pattern: /^pl-.*/,
	},
	{
		pattern: /^m-.*/,
	},
	{
		pattern: /^mx-.*/,
	},
	{
		pattern: /^my-.*/,
	},
	{
		pattern: /^mt-.*/,
	},
	{
		pattern: /^mr-.*/,
	},
	{
		pattern: /^mb-.*/,
	},
	{
		pattern: /^ml-.*/,
	},
	{
		pattern: /^rounded-.*/,
	},
	{
		pattern: /^border-.*/,
	},
	{
		pattern: /^shadow-.*/,
	},
	{
		pattern: /^w-.*/,
	},
	{
		pattern: /^gap-.*/,
	},
	{
		pattern: /^h-.*/,
	},
	{
		pattern: /^text-.*/,
	},
	{
		pattern: /^justify-.*/,
	},
	{
		pattern: /^align-.*/,
	},
	{
		pattern: /^row-.*/,
	},
	{
		pattern: /^col-.*/,
	},
	{
		pattern: /^grid-.*/,
	},
	{
		pattern: /^bg-.*/,
	},
	{
		pattern: /^order-.*/,
	},
	{
		pattern: /^max-.*/,
	},
	{
		pattern: /^min-.*/,
	},
	{
		pattern: /^rounded-.*/,
	},
	{
		pattern: /^drop-.*/,
	},
	{
		pattern: /^opacity-.*/,
	},
	{
		pattern: /^overflow-.*/,
	},
	{
		pattern: /^from-.*/,
	},
	{
		pattern: /^via-.*/,
	},
	{
		pattern: /^to-.*/,
	},
	{
		pattern: /^underline-.*/,
	},
	{
		pattern: /^overflow-.*/,
	},
	{
		pattern: /^font-.*/,
	},
];

const Page = (props) => {
	const { data: page, pageContext } = props;
	/*
	const { nodes } = pageContext;

	
	const colors = Object.values(nodes).flatMap((node) => {
		const values = [
			...Object.values(node).filter((value) => typeof value === 'string'),
			...Object.values(node?.custom || {}).flatMap((val) => {
				if (Array.isArray(val)) {
					return val;
				}

				return [val];
			}),
		];

		return values.filter((val) => {
			if (!val) {
				return false;
			}

			return Patterns.some((pat) => {
				// const result = pat.match(val);
				if (pat.pattern.test(val)) {
					console.log(pat.pattern, val, val.match(pat.pattern));
				}
				
				return pat.pattern.test(val);
			});
		});
	});

	console.log([...new Set(colors)]);
	*/

	return (
		<Context.Provider value={pageContext}>
			<Layout page={page.contentfulPage}>
				{page?.contentfulPage?.add?.map?.((item) => (
					<ContentItem
						key={item.id}
						nodeId={item.contentful_id}
						root
					/>
				))}
			</Layout>
		</Context.Provider>
	);
};

export default Page;

export const pageQuery = graphql`
	query ($url: String!) {
		contentfulPage(url: { eq: $url }) {
			id
			contentful_id
			url
			title
			name
			custom {
				id
				direction
				horizontalAlign
				verticalAlign
				textAlign
				style
				class
				height
				padding
				overflow
				margin
				background
				backgroundColor
				borderRadius
				border
				width
				shadow
				order
				opacity
				gradient
				container
			}
			add {
				__typename
				... on ContentfulReference {
					id
					contentful_id
				}
			}
		}
	}
`;
